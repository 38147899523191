<template>
  <div class="container">
    <div class="in-login">
      <a-row>
        <a-col class="acol" :xs="22" :sm="20" :md="20" :lg="20" :xl="14">
          <img class="img" src="./../../assets/sub/chatu2.jpg" alt="" />
          <div class="col-login">
            <div class="col-log">
              <img
                @click="onWx"
                class="WeChat"
                src="./../../assets/sub/tuceng.jpg"
              />
              <div class="wx-login" v-if="wxpassword == 1">
                <span class="login-text">欢迎登陆</span>
                <a-form-model
                  ref="ruleForm"
                  :model="user"
                  :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item
                    :label-col="formTailLayout.labelCol"
                    :wrapper-col="formTailLayout.wrapperCol"
                  >
                    <a-input
                      style="border-bottom: 2px solid #ebeff3"
                      v-model="user.code"
                      placeholder="请输入企业编号"
                    >
                      <a-icon
                        slot="prefix"
                        type="bank"
                        style="color: #3d73f6; font-size: 19px"
                      />
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item
                    :label-col="formTailLayout.labelCol"
                    :wrapper-col="formTailLayout.wrapperCol"
                  >
                    <a-input
                      style="border-bottom: 2px solid #ebeff3"
                      v-model="user.username"
                      placeholder="请输入用户名或手机"
                    >
                      <a-icon
                        slot="prefix"
                        type="user"
                        style="color: #3d73f6; font-size: 19px"
                      />
                    </a-input>
                  </a-form-model-item>

                  <a-form-model-item
                    v-if="user.type == 'password'"
                    :label-col="formTailLayout.labelCol"
                    :wrapper-col="formTailLayout.wrapperCol"
                  >
                    <a-input
                      style="border-bottom: 2px solid #ebeff3"
                      v-model="user.password"
                      class="no-autofill-pwd"
                      type="text"
                      placeholder="请输入密码"
                    >
                      <a-icon
                        slot="prefix"
                        type="lock"
                        style="color: #3d73f6; font-size: 19px"
                      />
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item
                    v-else
                    class="yanzhengma"
                    :label-col="formTailLayout.labelCol"
                    :wrapper-col="formTailLayout.wrapperCol"
                  >
                    <a-input
                      style="
                        position: relative;
                        border-bottom: 2px solid #ebeff3;
                      "
                      v-model="user.validate"
                      placeholder="请输入验证码"
                    >
                      <a-icon
                        slot="prefix"
                        type="lock"
                        style="color: #3d73f6; font-size: 19px"
                      />
                    </a-input>
                    <div
                      style="
                        position: absolute;
                        right: 4px;
                        bottom: -6px;
                        line-height: 32px;
                        cursor: pointer;
                      "
                    >
                      <span v-show="show" @click="getCode">获取验证码</span>
                      <span v-show="!show" class="count">{{ count }} s</span>
                    </div>
                  </a-form-model-item>
                  <span @click="onreset" class="forget">忘记密码?</span>
                </a-form-model>
                <a-form>
                  <a-form-item
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 16, offset: 4 }"
                  >
                    <a-button type="primary" @click="check" class="pri-btn">
                      登录
                    </a-button>
                  </a-form-item>
                </a-form>
                <div>
                  <span
                    v-if="user.type == 'password'"
                    @click="callback"
                    class="note"
                    >短信验证</span
                  >
                  <span
                    style="color: #4f61f1"
                    v-else
                    @click="callback"
                    class="note"
                    >密码登录</span
                  >
                </div>
              </div>
              <div v-else>
                <wxlogin
                  v-if="number == true"
                  class="wxlogin"
                  :appid="appid"
                  :scope="scope"
                  :href="href"
                  :redirect_uri="redirect_uri"
                  :state="state"
                ></wxlogin>
                <div
                  style="text-align: center; margin-top: 50%; width: 100%"
                  v-else
                >
                  <a-spin />
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <Verify
        ref="verify"
        :captcha-type="'blockPuzzle'"
        :img-size="{ width: '400px', height: '200px' }"
        @success="login"
      />
      <div
        style="
          position: absolute;
          bottom: 15px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          width: 100%;
        "
      >
        {{ infos.shortName }} &nbsp;&nbsp;{{ infos.icp }} &nbsp;&nbsp;{{
          infos.police == '' ? '' : infos.police
        }}
      </div>
    </div>
  </div>
</template>
<script>
import Verify from '../verifition/Verify'
import wxlogin from 'vue-wxlogin'
import http from '../../http'
// import { mapActions } from "vuex";
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
}
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16, offset: 4 },
}
export default {
  components: {
    wxlogin,
    Verify,
  },
  data() {
    return {
      number: false,
      wxpassword: 1,
      //定时器
      intervalId: null,
      //验证码
      show: true,
      count: '',
      timer: null,

      appid: '',
      scope: '',
      href: 'http://weixin.linkshang.com/cscn/wxCode.css',
      redirect_uri: '',
      state: '',
      checkNick: true,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      infos: {},
      mesage: '',
      user: {
        auto: 1,
        password: '',
        code: '',
        validate: '',
        type: 'password',
        username: '',
        captcha: {
          captchaVerification: '',
        },
      },
    }
  },
  methods: {
    onWx() {
      if (this.wxpassword == 0) {
        this.number = false
        this.wxpassword = 1
        this.getwxInfo()
        // console.log(1);
      } else if (this.wxpassword == 1) {
        this.wxpassword = 0
      }
    },

    login(params) {
      this.user.captcha.captchaVerification = params.captchaVerification
      if (this.user.type == 'password') {
        this.password()
      } else if (this.user.type == 'mobile') {
        const TIME_COUNT = 60
        this.getmobileCode()
        if (!this.timer) {
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    //验证码
    getCode() {
      if (this.user.username != '') {
        this.$refs.verify.show()
      } else {
        this.$message.error('手机号码不能为空')
        return
      }
    },
    callback() {
      this.user.password = ''
      this.user.username = ''
      if (this.user.type == 'mobile') {
        this.user.type = 'password'
      } else if (this.user.type == 'password') {
        this.user.type = 'mobile'
      }
    },
    onreset() {
      this.$router.push('reset')
    },
    password() {
      this.form.validateFields(err => {
        if (!err) {
          this.getlogin()
        } else {
          this.$message.error('登录失败')
        }
      })
    },
    check() {
      this.mesage = ''
      if (this.user.type == 'password') {
        if (this.user.code == '') {
          this.mesage = '企业编号、'
        }
        if (this.user.username == '') {
          this.mesage = this.mesage + '用户名或手机号、'
        }
        if (this.user.password == '') {
          this.mesage = this.mesage + '密码'
        }
        if (
          this.user.code == '' ||
          this.user.username == '' ||
          this.user.password == ''
        ) {
          this.$message.error(this.mesage + '不能为空')
        } else {
          this.$refs.verify.show()
        }
      } else if (this.user.type == 'mobile') {
        this.password()
      }
    },
    async getuserthing() {
      try {
        const res = await http.getuserthing()
        const { success, data } = res.data
        if ((success, data)) {
          //   console.log(data);
          localStorage.setItem('store-userlist', JSON.stringify(data.user))
          this.$store.state.userlist = data.user
          this.$router.push('/welcome')
          //   console.log(this.$store.state.userlist);
        }
      } catch (ex) {
        console.log('error', ex)
      }
    },
    async getlogin() {
      try {
        const res = await http.getlogin(this.user)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
          localStorage.setItem('logins', JSON.stringify(this.user))
          localStorage.setItem('openKeys', ['/welcome'])
          localStorage.setItem('selectedKeys', ['/welcome'])
          this.getuserthing()
        } else {
          this.$message.error(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getmobileCode() {
      try {
        const res = await http.getmobileCode(this.user.code, this.user.username)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('短信已发送')
        } else {
          this.show = true
          clearInterval(this.timer)
          this.timer = null
          this.$message.error(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getweixin() {
      try {
        const res = await http.getweixin()

        const { success } = res.data
        if (success) {
          this.number = true
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getwxInfo() {
      try {
        const res = await http.getwxInfo()
        const { success, data } = res.data
        if (success) {
          this.appid = data.appId
          this.scope = data.scope
          this.redirect_uri = data.redirect_uri
          this.state = data.state
          this.href = data.href
          //   console.log(data);
          if (this.appid != '') {
            this.getweixin()
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getprojectInfoall() {
      try {
        const res = await http.getprojectInfoall()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.infos = data.info
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    wxtimer() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.getwxInfo() //加载数据函数
      }, 30000)
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId) //清除计时器
      this.intervalId = null //设置为null
    },
  },
  mounted() {
    this.wxtimer()

    this.getwxInfo()
    this.getprojectInfoall()
    const loginuser = JSON.parse(localStorage.getItem('logins'))
    this.user.code = loginuser.code
    this.user.password = loginuser.password
    this.user.username = loginuser.username
    // console.log(loginuser);
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear()
  },
}
</script>
<style scoped>
.welcome-text {
  display: inline-block;
  width: 100%;
  margin-top: 130px;
  text-align: center;
  color: #000;
  font-size: 24px;
  font-weight: 500;
}
.container {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  overflow: hidden;
}
@media screen and (max-width: 845px) {
  .acol {
    width: 730px;
  }
  .ant-form-item-control {
    width: 70%;
  }
  .in-login {
    overflow: auto;
  }
  .container {
    width: 845px;
  }
}
@media screen and (min-width: 2000px) {
  .in-login {
    background-image: url('../../assets/sub/beijingx3.png');
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 2000px) {
  .in-login {
    background-image: url('../../assets/sub/beijingx2.png');
    width: 100%;
    height: 100%;
  }
}
.ant-form {
  margin-top: 30px;
}
.forget {
  color: #666;
  position: absolute;
  right: 75px;
  z-index: 50;
  bottom: 33px;
  cursor: pointer;
}
.note {
  color: #000;
  position: absolute;
  left: 75px;
  z-index: 50;
  bottom: 33px;
  cursor: pointer;
}
.loading {
  text-align: center;
  margin-top: 130px;
}
::v-deep .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 10px;
}
::v-deep .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  text-align: center;
  margin: -12px auto;
}
.ant-form-item {
  margin-bottom: 15px;
}
.yanzhengma {
  display: flex;
  flex-direction: row;
}
.login-login,
.ant-row {
  height: 100%;
}
.ant-row {
  position: relative;
}
.ant-col {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  z-index: 100;
}
.img {
  height: 85%;
  margin-left: 11px;
  margin-top: 3px;
}
.col-login {
  width: 45%;

  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.col-log {
  background-color: #fff;
  width: 350px;
  position: absolute;
  height: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  box-shadow: 0 6px 26px 2px rgb(202 204 250);
}
.ant-btn-primary {
  width: 100%;
  height: 40px;
  border-radius: 25px;
}
.no-autofill-pwd {
  -webkit-text-security: disc !important;
}
.login-text {
  display: block;
  font-size: 30px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 45px;
}
::v-deep .ant-input {
  box-shadow: none;
  border-radius: 0;
  border: none;
  border-style: none;
  font-size: 15px;
  color: #666;
}
.wxlogin {
  z-index: 500;
  margin-top: 60px;
  text-align: center;
}
.WeChat {
  z-index: 101;
  width: 65px;
  position: absolute;
  right: 6px;
  top: 6px;
}
.WeChat:hover {
  cursor: pointer;
}
::v-deep .ant-spin-dot-item {
  width: 14px;
  height: 14px;
}
::v-deep .ant-spin-dot {
  font-size: 30px;
}
</style>
